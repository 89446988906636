import br from 'assets/locale/br.json';
import es from 'assets/locale/es.json';
import en from 'assets/locale/en.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'br',
    debug: false,
    interpolation: { escapeValue: false },
    resources: {
      br: { translation: br },
      en: { translation: en },
      es: { translation: es },
    },
    react: {
      bindI18n: 'loaded languageChanged',
      bindI18nStore: 'added',
    },
  });

export default i18n;
